/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js
 * - /npm/baguettebox.js@1.11.0/dist/baguetteBox.min.js
 * - /npm/lazysizes@4.1.4/lazysizes.min.js
 * - /npm/lazysizes@4.1.4/plugins/unveilhooks/ls.unveilhooks.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
